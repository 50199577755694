<template>
  <Page :loading="loading" :hasBg="false" bgClass="bg-white">
    <Navbar title="默认收款卡" @clickLeft="handleClickBack"> </Navbar>
    <div class="font-12 bg-color lh-32 px-16 text_secondary">
      注：运费将结算到您的默认收款卡中，请务必选择正确！
    </div>
    <div class="bank-list-contain pa-16">
      <div
        class="lh-40 d-flex bdb font-16 justify-space-between"
        v-for="(item, index) in bankList"
        :key="index"
        @click="() => handleChangeDefaultBank(item, index)"
      >
        <div>{{ item.bankName }}（{{ item.bankAccount.substring(15) }}）</div>
        <span v-show="item.defaultState === 1" class="text_primary d-flex font-14 lh-38"
          ><Icon name="xz" svgClass="mt-10 mr-4" />默认</span
        >
      </div>
    </div>
  </Page>
</template>
<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import { getBankList, apiChangeDefaultBank } from '@api/mine';
import { Toast } from 'vant';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  name: 'ChangeBankCard',
  setup() {
    const bankList = ref([]);
    const router = useRouter();
    const loading = ref(true);
    const handleChangeDefaultBank = (item, index) => {
      apiChangeDefaultBank({
        payeeId: item.payeeId
      }).then(() => {
        bankList.value.map(item => (item.defaultState = 2));
        bankList.value[index].defaultState = 1;
        Toast.success('切换银行卡成功！');
      });
    };
    const handleClickBack = () => {
      router.push(PageEnum.BANK_CHANGE_DEFAULT);
    };
    onMounted(() => {
      getBankList().then(res => {
        bankList.value = res.data;
        loading.value = false;
      });
    });
    return {
      loading,
      bankList,
      handleClickBack,
      handleChangeDefaultBank
    };
  }
});
</script>
<style lang="less"></style>
